import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object
  .keys(Icons)
  .filter(key => key !== "fas" && key !== "prefix" )
  .map(icon => Icons[icon])

library.add(...iconList)

const Pulse = 'https://hrflow-ai.imgix.net/pulse.svg'
const Brain = 'https://hrflow-ai.imgix.net/brain.svg'
const Jug = 'https://hrflow-ai.imgix.net/jug.svg'
const Spin = 'https://hrflow-ai.imgix.net/spin.svg'
const Graph = 'https://hrflow-ai.imgix.net/graph.svg'
const Bracket = 'https://hrflow-ai.imgix.net/bracket.svg'

const StyledNavWrapper = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-left: 5rem;
    font-family: ${({ theme }) => theme.fonts.plainThin};
  }
`
const StyledButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .cta-btn {
    width: auto;
    height: auto;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.white};
    transition: 20ms ease-out;
    font-size: 1rem;
    padding: 0.7rem 1rem 0.8rem 1rem;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
    }
    max-height: 50px;
    &.register {
      margin: 0 1rem;
    }
  }
  .cta-btn.empty {
    border: 1px solid ${({ theme }) => theme.colors.white};
    background: transparent;
    color: ${({ theme }) => theme.colors.white};
    &:hover {
      background-color: rgba(36, 204, 212, 0.4);
    }
  }
  .inline-btn {
    margin: 0 1.7rem;
  }
`
const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 39.25rem;
  background: transparent;
`
const StyledAnchor = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  position: relative;
  padding: 0;
  cursor: pointer;
  &::before {
    transition: 200ms ease-out;
    height: 0.1563rem;
    content: "";
    position: absolute;
    background-color: ${({ theme }) => theme.colors.white};
    width: 0%;
    bottom: -0.125rem;
  }
  &:hover::before {
    width: 100%;
  }
`

const StyledAnchorLink = styled(Link)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  position: relative;
  padding: 0;
  cursor: pointer;
  &::before {
    transition: 200ms ease-out;
    height: 0.1563rem;
    content: "";
    position: absolute;
    background-color: ${({ theme }) => theme.colors.white};
    width: 0%;
    bottom: -0.125rem;
  }
  &:hover::before {
    width: 100%;
  }
  &.lang {
    opacity: 0.7;
  }
  &.active {
    opacity: 1;
  }
`

const Dropdown = styled.div`
  position: relative;
  padding: 1.5rem 0;
  &:hover {
    .dropdown-menu {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.1s;
    }
  }
  &.langs {
    display: flex;
    .lang:first-of-type {
      margin-right: 0.5rem;
    }
  }
`

const DropdownMenu = styled.div`
    left: -100%;
    z-index: 10;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-box-shadow: 0 1.5rem 3rem rgba(38,46,79,.1);
    box-shadow: 0 1.5rem 3rem rgba(38,46,79,.1);
    -webkit-transform: translateX(-15%);
    -ms-transform: translateX(-15%);
    transform: translateX1(-15%);
    visibility: hidden;
    opacity: 0;
    width: 28rem;
    min-height: 20rem;
    background: white;
    position: absolute;
    top: 4rem;
    border-radius: 5px;
    color: #00495d;
    font-size: 0.9rem;
`

const DropdownMenuDouble = styled(DropdownMenu)`
  display: flex;
  justify-content: space-between;
  width: 60rem;
`

const Left = styled.div`
  flex: 1 1 52%;
  padding: 2rem;
  width: 100%;
`

const Right = styled.div`
  flex: 1 1 42%;
  background-color: #f6f9fc;
  border-radius: 5px;
  padding: 2rem;
  width: 100%;
`

const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index)
]

const Navbar = ({ items, frontmatter, slug }) => {
  let url = typeof window !== 'undefined' ? window.location.pathname : '';
  let path = url.split('/')
  if((path.length === 3 && path.indexOf('fr') == -1) || path.length > 3) {
    path = path[path.length - 2] + '/'
  } else {
    path = ""
  }
  const navItem = {
    name: slug === "/fr" ? "Tarifs" : "Pricing",
    link: slug === "/fr" ? "/fr/pricing/" : "/pricing/"
  }
  return (
    <StyledNavWrapper>
      <StyledNav>
        {insert(items, 1, navItem).map((item, index) => {
          return (
            <Dropdown>
              <StyledAnchorLink to={item.link || '#'}>
                {item.name}
              </StyledAnchorLink>
              {item.hasOwnProperty('modal') && (
                <DropdownMenuDouble className="dropdown-menu">
                  <Left>
                    {item.modal.left.featured[0].items[0].title && item.modal.left.featured.map((item, index) => {
                      return (
                        <div className="section-featured">
                          <span className="title">
                            {item.name}
                          </span>
                          <ul className="section-featured__list">
                            {item.items.map((item, key) => {
                              return (
                                <li className="section-featured__list-item">
                                  <span className="section-featured__list-item--logo">
                                    {item.icon && (<FontAwesomeIcon className="icon-right" icon={item.icon} />)}
                                    {item.image && (<img src={item.image} alt="bracket" />)}
                                  </span>
                                  <Link to={item.link} className="section-featured__list-item--content">
                                    <span className="headline">{item.title}</span>
                                    <span className="subheadline">{item.description}</span>
                                  </Link>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      )
                    })}
                    <div className="section-featured">
                      <div className="col-list__wrapper">
                        {item.modal.left.simple[0].name && item.modal.left.simple.map((item, index) => {
                          return (
                            <div className="section-featured__list-item columns">
                              <span className="title" style={{marginTop: '1rem'}}>{item.name}</span>
                              <div className="block">
                                {item.items.map((i, index) => {
                                  return (
                                    <a href={i.link} className="block__item">
                                      <span className="logo">
                                        {i.icon && (<FontAwesomeIcon className="icon-right" icon={i.icon} />)}
                                        {i.image && (<img src={i.image} alt="bracket" />)}
                                      </span>
                                      <span className="headline">{i.title}</span>
                                    </a>
                                  )
                                })}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </Left>
                  <Right>
                    {item.modal.right.featured[0].name && item.modal.right.featured.map((item, index) => {
                      return (
                        <div className="section-featured">
                          <span className="title">
                            {item.name}
                          </span>
                          <ul className="section-featured__list">
                            {item.items.map((item, key) => {
                              return (
                                <li className="section-featured__list-item">
                                  <span className="section-featured__list-item--logo">
                                    {item.icon && (<FontAwesomeIcon className="icon-right" icon={item.icon} />)}
                                    {item.image && (<img src={item.image} alt="bracket" />)}
                                  </span>
                                  <Link to={item.link} className="section-featured__list-item--content">
                                    <span className="headline">{item.title}</span>
                                    <span className="subheadline">{item.description}</span>
                                  </Link>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      )
                    })}
                    
                    {item.modal.right.media && (
                      <div className="section-featured">
                        <span className="title">
                          {item.modal.right.media.name}
                        </span>
                        <a href={item.modal.right.media.link} className="visual-item">
                          <img src={item.modal.right.media.photo} alt={item.modal.right.media.alt} />
                          <span className="headline">{item.modal.right.media.text}</span>
                        </a>
                      </div>
                    )}
                
                  </Right>
                </DropdownMenuDouble>
              )}
            </Dropdown>
          )
        })}
        <StyledButtons>
          <Link className="cta-btn empty" to={frontmatter.demoButtonLink}>
            {frontmatter.demoButtonText}
          </Link>
        </StyledButtons>
      </StyledNav>
      <StyledButtons>
        <Dropdown className="langs">
          <StyledAnchorLink to={`/${path}`} className={slug !== '/fr' ? 'lang active': 'lang'}>
            EN
          </StyledAnchorLink>
          <StyledAnchorLink to={`/fr/${path}`}  className={slug === '/fr' ? 'lang active': 'lang'}>
            FR
          </StyledAnchorLink>
        </Dropdown>
        <Link className="cta-btn empty register"   to={frontmatter.registerButtonLink}>
          {frontmatter.registerButtonText}
        </Link>
        <Link className="cta-btn" to={frontmatter.bookingButtonLink}>
          {frontmatter.bookingButtonText}
        </Link>
      </StyledButtons>
    </StyledNavWrapper>
  )
}

export default Navbar
