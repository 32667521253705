import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import spins from "content/images/about-spins.svg"
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'

const saasImg = "https://hrflow-ai.imgix.net/labs/saas.png?w=690&h=400&fit=clip"


const StyledCard = styled.div`
  margin: auto;
  margin-top: -13.5rem;
  background: url(${spins}), linear-gradient(268.65deg, #16385A 71.18%, #07192C 94.99%);
  background-size: cover;
  margin-bottom: 3rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 81.9rem;
    border-radius: 0.625rem;
  }
  .content {
    padding: 4.37rem 1rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      padding: 4.37rem;
    }
    text-align: left;
    .title {
      font-size: 1.75rem;
      line-height: 2.2rem;
      color: white;
      margin-bottom: 1rem;
    }
    .text {
      color: rgb(157, 185, 214);
      font-weight: 300;
      font-size: 0.87rem;
      margin-bottom: 1.62rem;
    }
    .about-label {
      color: rgb(29, 175, 182);
      font-weight: 700;
      font-size: 0.95rem;
      line-height: 0.8rem;
      letter-spacing: 0.19em;
      margin-bottom: 1.25rem;
      text-alig: left;
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    a:first-of-type {
      margin-right: 0;
      margin-bottom: 1rem;
    }
    a {
      width: 100%;
      text-align: center;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      flex-direction: row;
      a:first-of-type {
        margin-bottom: 0;
        margin-right: 1rem;
      }
      a {
        width: unset;
      }
    }
  }
  .image {
    display: none;
    background-image: url(${saasImg});
    background-size: auto;
    margin-top: 1rem;
    background-position: bottom;
    background-repeat: no-repeat;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      display: block;
      border-bottom-right-radius: 0.625rem;
    }
  }
  .image-el {
    display: block; 
    width: 90%;
    height: auto;
    margin: auto;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      display: none;
    }
  }
`

const StyledButton = styled(Link)`
  font-size: 0.9rem;
  line-height: 1.2rem;
  padding: 1rem;  
  background: rgb(29, 175, 182);
  border-radius: 0.375rem;
  color: white;
`
const StyledButtonSecondary = styled(StyledButton)`
  background: rgb(24, 61, 99);
  color: rgb(157, 185, 214);
}
`

const About = ({ content, slug }) => (
  <StyledCard>
    <div className="content">
      <div className="about-label">{content.label}</div>
      <div className="title">
        {content.title} <br /> {content.titlePrefix}
      </div>
      <div className="text">
        {content.subtitle} <br />
        {content.subtitlePrefix}
      </div>
      <div className="buttons">
        <StyledButton to={content.buttonBookingLink} rel="noopener" target="_blank" >{content.buttonBookingText}</StyledButton>
        <StyledButtonSecondary to={content.buttonSignupLink} rel="noopener" target="_blank">{content.buttonSignupText}</StyledButtonSecondary>
      </div>
    </div>
    <div className="image">
    </div>
    <img
      alt="saas image"
      className="lazyload image-el"
      width={960}
      height={400}
      data-src={saasImg}
    />
  </StyledCard>
)

export default About
