import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"

import Context from "context"
import { detectMobileAndTablet, isSSR } from "utils/"
import ContentWrapper from "styles/contentWrapper"
import Logo from "./logo"
import Sidebar from "./sidebar"
import Navbar from "./navbar"

const StyledHeader = motion.custom(styled.header`
  width: 100%;
  height: ${({ theme }) => theme.headerHeight};
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  background: transparent;
  transition: 0.6s;
  &.sticky {
    background: ${({ theme}) => theme.colors.secondary};
    height: 5rem;
    .logo {
      img  {
        width: 8rem;
      }
    }
  }
`)

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

// https://css-tricks.com/hamburger-menu-with-a-side-of-react-hooks-and-styled-components/
const StyledBurger = styled.button`
  z-index: 12;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 0.625rem;
    transition: all 0.3s ease-in-out;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
      background: ${({ open }) => (open ? `#00495d` : "white")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
      background: ${({ open }) => (open ? `#00495d` : "white")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
      background: ${({ open }) => (open ? `#00495d` : "white")};
    }
  }
`

const Header = ({ content, lang, page }) => {
  const { exports, frontmatter } = content[0].node
  const { items } = exports
  const { isIntroDone } = useContext(Context).state
  const [open, setOpen] = useState(false)
  const [windowWidth, setWindowWidth] = useState(0)
  const [offsetY, setOffsetY] = useState(0)
  const [headerClassName, setHaderClassName] = useState('')
  useEffect(() => {
    let handleWindowSizeChange
    // if (isSSR) is necessary to prevent error during the gatsby build
    if (!isSSR) {
      handleWindowSizeChange = () => setWindowWidth(window.innerWidth)
      // set initial innerWidth when component mounts
      setWindowWidth(window.innerWidth)
    }
    // Add event listener to update windowWidth in state
    window.addEventListener("resize", handleWindowSizeChange)
    return () => window.removeEventListener("resize", handleWindowSizeChange)
  }, [windowWidth])

  // Required for animation - start after the splashScreen sequence is done
  const controls = useAnimation()
  useEffect(() => {
    if (isIntroDone)
      controls.start({ opacity: 1, y: 0, transition: { delay: 0.2 } })
  }, [isIntroDone, controls])

  useEffect(() => {
    
    if(page === '404') {
      setHaderClassName('sticky')
      setOffsetY(1)
    }
  }, [])

  useEffect(() => {
    
    // if (isSSR) is necessary to prevent error during the gatsby build
    if (!isSSR) {
      window.onscroll = function() {
        if( page !== "404") {
          if(window.pageYOffset > 30) {
            setOffsetY(1)
            setHaderClassName('sticky')
          } else {
            setOffsetY(0)
            setHaderClassName('')
          }
        }
      };
      // set initial innerWidth when component mounts
    }
    // Add event listener to update windowWidth in state
  }, [offsetY])
  const slug = lang === "fr" ? "/fr" : "/"
  let navigation
  if (detectMobileAndTablet(windowWidth)) {
    navigation = (
      <>
        <StyledBurger
          // aria-controls="sidebar"
          aria-label="button"
          open={open}
          onClick={() => setOpen(!open)}
        >
          <div />
          <div />
          <div />
        </StyledBurger>
        <Sidebar items={items} frontmatter={frontmatter} slug={slug} id="sidebar" open={open} setOpen={setOpen} />
      </>
    )
  } else {
    navigation = <Navbar items={items} frontmatter={frontmatter} slug={slug} />
  }
  return (
    <StyledHeader initial={{ opacity: 0, y: -10 }} className={headerClassName} animate={controls}>
      {/* add blur class to body when sidebar is opened */}
      <Helmet bodyAttributes={{ class: open ? "blur" : "" }} />
      <StyledContentWrapper>
        <Link to={slug} aria-label="home">
          <Logo color="primary" size="2rem" />
        </Link>
        {navigation}
      </StyledContentWrapper>
    </StyledHeader>
  )
}

export default Header
