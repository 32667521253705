import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import Logo from "./logo"
import ContentWrapper from "styles/contentWrapper"
import About from "components/common/about"


const mediumIcon = "https://hrflow-ai.imgix.net/medium.svg"
const twitterIcon = "https://hrflow-ai.imgix.net/twitter.svg"
const facebookIcon = "https://hrflow-ai.imgix.net/facebook.svg"
const instagramIcon = "https://hrflow-ai.imgix.net/instagram.svg"
const linkedinIcon = "https://hrflow-ai.imgix.net/linkedin.svg"
const rgdpImg = "https://hrflow-ai.imgix.net/rgdp.svg"

const FooterSection = styled.footer`
  background-color: rgb(7, 25, 44);
  color: white;
  width: 100%;
  height: fit-content;
  padding: 3.5rem 0;
  padding-top: 7rem;
  padding-bottom: 0;
  margin-top: 8rem;
`



const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
  }
`

const Container = styled.div`
  max-width: none;
  padding-left: 12%;
  padding-right: 12%;
  padding-top: 3rem;
  @media (max-width: 1200px) {
      padding-left: 2rem;
      padding-right: 2rem;
  }
`

const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`
const Left = styled.div`
  display: flex;
  margin-left: 3rem;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
    margin-left: 0;
    margin-bottom: 3rem;
  }
`

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Divider = styled.div`
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, .8);
  margin: 2.5rem 0 1.5rem 0;
`

const Title = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: .065rem;
  color: rgb(29, 175, 182);
  margin-bottom: 1rem;
  margin-left: 5rem;
  @media (max-width: 1500px) {
    margin-left: 2.5rem
  }
  @media (max-width: 1200px) {
    margin-right: 1rem;
    margin-left: 0;
    min-width: 8rem;
  }
`

const Items = styled.ul`
  list-style: none;
  margin-left: 5rem;
  padding-left: 0;
  @media (max-width: 1500px) {
    margin-left: 2.5rem
  }
  @media (max-width: 1200px) {
    margin-right: 1rem;
    margin-left: 0;
    margin-bottom: 2rem;
    min-width: 8rem;
  }
`

const Item = styled.li`
  margin-bottom: .5rem;
  opacity: .8;
  font-size: .8rem;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6rem;
  letter-spacing: -.015rem;
  color: #fff;
  white-space: nowrap;
  a {
    color: unset!important;
    text-decoration: none!important;
  }
`

const Text = styled.div`
  margin-bottom: .5rem;
  opacity: .8;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: -.015rem;
  color: #fff;
  margin-bottom: 1rem;
  margin-top: .4rem;
  max-width: 300px;
  @media (max-width: 1200px) {
    margin-bottom: 2rem;
  }

`

const LogoImg = styled.img`
  height: 3.05rem;
  width: 8.25rem;
`


const Icon = styled.img`
    height: .85rem;
`

const TextRight = styled.div`
    font-size: .9rem;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -.015rem;
    text-align: right;
    color: #fff;
    text-align: left;
    margin-left: 0.5rem;
`
const FooterBottomWrapper = styled.div`
  max-width: 100%;
  background: #1A8E94;
`

const FooterBottom = styled(ContentWrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: white;
  margin-top: 3.5rem;
  @media (max-width: 1200px) {
      flex-direction: column;
  }
`

const Social = styled.div`
    display: flex;
    justify-content: space-between;
    a {
        width: 2.4rem;
        height: 2.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #1DAFB6;
        border-radius: 0.25rem;
        margin: 0 0.2rem;
    }
    @media (max-width: 1200px) {
        margin: 1rem 0;
        max-width: 400px;
    }
`

/* const FooterBottom = styled.div`
  display: flex;
  padding-bottom: 2rem;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
` */

const Footer = ({content, lang}) => {
  const { frontmatter, body, exports } = content[0].node
  const { items } = exports
  const slug = lang === "fr" ? "/fr" : ""
  return (
    <FooterSection>
      <About content={frontmatter} slug={slug} />
      <StyledContentWrapper>
        <FooterTop>
          <Right>
            <div>
              <Logo style={{ marginBottom: '1rem' }} />
              <div>
                <Text>
                  <MDXRenderer>{body}</MDXRenderer>
                </Text>
                <div>
                  <LogoImg
                    alt="logo"
                    width={140}
                    height={50}
                    className="lazyload"
                    src="https://hrflow-ai.imgix.net/empty.svg"
                    data-src={rgdpImg}
                  />
                </div>
              </div>
            </div>
          </Right>
          <Left>
            {items.map((item, key) => {
              return (
                <div index={`item-${key}`}>
                  <Title>
                    {item.name}
                  </Title>
                  <Items>
                    {item.links.map((l, k) => {
                      return (
                        <Item index={k}><Link to={l.link}>{l.text}</Link></Item>
                      )
                    })}
                  </Items>
                </div>
              )
            })}
          </Left>
        </FooterTop>
      </StyledContentWrapper>
      <FooterBottomWrapper>
            <FooterBottom>
              <TextRight>
                {frontmatter.copyRight}
              </TextRight>
              <Social>
                  <a target="blank" rel="nofollow" href="https://linkedin.com/company/hrflowai"><Icon height="15" width="15" src={linkedinIcon} className="social-logo" alt="linkedin logo"/></a>
                  <a target="blank" rel="nofollow" href="https://twitter.com/hrflowai"><Icon height="15" width="15" src={twitterIcon} className="social-logo" alt="twitter logo" /></a>  
                  <a target="blank" rel="nofollow" href="https://www.facebook.com/hrflowai"><Icon height="15" width="15" src={facebookIcon} className="social-logo" alt="fb logo" /></a>
                  <a target="blank" rel="nofollow" href="https://medium.riminder.net/"><Icon height="15" width="15" src={mediumIcon} className="social-logo" alt=" medium logo" /></a>  
                  <a target="blank" rel="nofollow" href="https://www.instagram.com/hrflowai/"><Icon height="15" width="15" src={instagramIcon} className="social-logo" alt=" instagram logo"  /></a>            
              </Social>
            </FooterBottom>
        </FooterBottomWrapper>
    </FooterSection>
  )
}

export default Footer;