import React, {useState} from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faChevronRight,
  faGift,
  faBriefcase,
  faAddressBook,
  faRocket,
  faCode,
  faBrain,
  faComment,
  faWrench,
  faBook,
  faDownload,
  faAt,
  faBookOpen,
  faChalkboardTeacher,
  faFileVideo,
  faRss,
  faQuestionCircle,
  faCompressArrowsAlt,
  faHeartbeat,
  faHandsHelping,
  faHeadset,
  faShieldAlt,
  faLock,
  faNewspaper,
  faUserMd,
  faFire,
  faBuilding,
} from '@fortawesome/free-solid-svg-icons'

const Pulse = 'https://hrflow-ai.imgix.net/pulse.svg'
const Brain = 'https://hrflow-ai.imgix.net/brain.svg'
const Jug = 'https://hrflow-ai.imgix.net/jug.svg'
const Spin = 'https://hrflow-ai.imgix.net/spin.svg'
const Graph = 'https://hrflow-ai.imgix.net/graph.svg'
const Bracket = 'https://hrflow-ai.imgix.net/bracket.svg'

const StyledBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9;
  transition: all 0.3s ease-in-out;
  display: ${({ open }) => (open ? "block" : "none")};
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`

const StyledContainer = styled.div`
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  outline: 0;
  z-index: 10;
  transition: all 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: ${({ theme }) => theme.colors.white};
  height: 100vh;
  overflow-y: auto;
  width: 90%;
  max-width: 400px;
  text-align: left;
  padding: 2rem;
  position: relative;
  right: 0;
  margin-left: auto;
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
  .nav-link {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    padding: 1.5rem 0;
  }
  .cta-btn {
    width: auto;
    height: auto;
    margin: 1.5rem auto;
    padding: 1rem 1.5rem;
    font-weight: 700;
    font-size: 1.5rem;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 0.125rem solid ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.background};
  }

  .nav-drop {
    font-size: 1.5rem;
    color: #00495d;
    font-weight: bold;
    padding-bottom: 1.3rem;
    display: flex;
    justify-content: space-between;
    &:first-of-type {
      margin-top: 5rem;
    }
  }
`
const StyledButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
  .cta-btn {
    width: 100%;
    height: auto;
    font-weight: 700;
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
    text-transform: uppercase;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    background: ${({ theme }) => theme.colors.secondary};
    transition: 20ms ease-out;
    font-size: 1rem;
    padding: 0.7rem 1rem 0.8rem 1rem;
    margin: 0;
    &:hover {
      opacity: 0.9;
    }
    max-height: 50px;
  }
  .cta-btn.empty {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background: transparent;
    color: ${({ theme }) => theme.colors.primary};
    margin-top: 1rem;
  }
`
const StyledDiv = styled.div`
`

const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index)
]


const Sidebar = ({ items, frontmatter, open, setOpen, slug }) => {
  const [openedDropDown, setOpenedDropDown] = useState('')
  const navItem = {
    name: "Pricing",
    link: "/pricing/"
  }
  return (
    <>
      <StyledContainer open={open} aria-hidden={!open} tabIndex={open ? 1 : -1}>
        <StyledNav>
          <StyledDiv>
            {insert(items, 1, navItem).map((item, index) => {
              return (
                <>
                  {item.hasOwnProperty("link") ? (
                    <Link className="nav-drop" style={{marginTop: '0'}}  to={item.link || '#'}>
                      <span>{item.name}</span>
                    </Link>
                  ) : (
                    <div className="nav-drop" onClick={() => openedDropDown === item.name ? setOpenedDropDown("") : setOpenedDropDown(item.name)}>
                      <span>{item.name}</span>
                      <FontAwesomeIcon className="icon-right" icon={faChevronRight} />
                    </div>
                  )}
                  {item.hasOwnProperty('modal') && openedDropDown === item.name && (
                    <>
                        {item.modal.left.featured[0].items[0].title && item.modal.left.featured.map((item, index) => {
                          return (
                            <div className="section-featured mobile">
                              <span className="title">
                                {item.name}
                              </span>
                              <ul className="section-featured__list">
                                {item.items.map((item, key) => {
                                  return (
                                    <li className="section-featured__list-item">
                                      <span className="section-featured__list-item--logo">
                                        {item.icon && (<FontAwesomeIcon className="icon-right" icon={item.icon} />)}
                                        {item.image && (<img src={item.image} alt="bracket" />)}
                                      </span>
                                      <Link to={item.link} className="section-featured__list-item--content">
                                        <span className="headline">{item.title}</span>
                                        <span className="subheadline">{item.description}</span>
                                      </Link>
                                    </li>
                                  )
                                })}
                              </ul>
                            </div>
                          )
                        })}
                        <div className="section-featured mobile">
                          <div className="col-list__wrapper">
                            {item.modal.left.simple[0].name && item.modal.left.simple.map((item, index) => {
                              return (
                                <div className="section-featured__list-item columns">
                                  <span className="title" style={{marginTop: '1rem'}}>{item.name}</span>
                                  <div className="block">
                                    {item.items.map((i, index) => {
                                      return (
                                        <a href={i.link} className="block__item">
                                          <span className="logo">
                                            {i.icon && (<FontAwesomeIcon className="icon-right" icon={i.icon} />)}
                                            {i.image && (<img src={i.image} alt="bracket" />)}
                                          </span>
                                          <span className="headline">{i.title}</span>
                                        </a>
                                      )
                                    })}
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        {item.modal.right.featured[0].name && item.modal.right.featured.map((item, index) => {
                          return (
                            <div className="section-featured mobile">
                              <span className="title">
                                {item.name}
                              </span>
                              <ul className="section-featured__list">
                                {item.items.map((item, key) => {
                                  return (
                                    <li className="section-featured__list-item">
                                      <span className="section-featured__list-item--logo">
                                        {item.icon && (<FontAwesomeIcon className="icon-right" icon={item.icon} />)}
                                        {item.image && (<img src={item.image} alt="bracket" />)}
                                      </span>
                                      <Link to={item.link} className="section-featured__list-item--content">
                                        <span className="headline">{item.title}</span>
                                        <span className="subheadline">{item.description}</span>
                                      </Link>
                                    </li>
                                  )
                                })}
                              </ul>
                            </div>
                          )
                        })}
                        
                        {item.modal.right.media && (
                          <div className="section-featured mobile">
                            <span className="title">
                              {item.modal.right.media.name}
                            </span>
                            <a href={item.modal.right.media.link} className="visual-item">
                              <img src={item.modal.right.media.photo} alt={item.modal.right.media.alt} />
                              <span className="headline">{item.modal.right.media.text}</span>
                            </a>
                          </div>
                        )}
                      
                    </>
                  )}
                </>
              )
            })}
          </StyledDiv>
          <StyledButtons>
            <Link className="cta-btn" to={frontmatter.registerButtonLink}>
              {frontmatter.registerButtonText}
            </Link>
            <Link className="cta-btn empty" to={frontmatter.bookingButtonLink}>
              {frontmatter.bookingButtonText}
            </Link>
          </StyledButtons>
        </StyledNav>
      </StyledContainer>
      <StyledBackdrop open={open} />
    </>
  )
}

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default Sidebar
