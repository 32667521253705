import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledLogo = styled.div`
  position: relative;
  z-index: 8;
  /* Disable effects when sidebar is open */
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
  img {
    width: 9rem;
    height: auto;
    transition: 0.6s;
  }
`

const Logo = ({ size, color }) => (
  <StyledLogo className="logo" color={color} size={size}>
    <img src="https://hrflow-ai.imgix.net/logo.svg" width="162" height="61.91" alt="HrFlow.ai logo" />
  </StyledLogo>
)

Logo.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
}

export default Logo
