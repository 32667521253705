import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import styled, { ThemeProvider } from "styled-components"
import "fontsource-roboto/400.css"
import "fontsource-roboto/700.css"

import { lightTheme, darkTheme } from "styles/theme"
import { useDarkMode } from "src/hooks"
import GlobalStyle from "styles/globalStyle"
import Header from "./header"
import Footer from "./footer"
import CookieBar from "components/cookieBar"
import { useCookieBar } from "config"

// https://medium.com/@chrisfitkin/how-to-smooth-scroll-links-in-gatsby-3dc445299558
if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}

const StyledLayoutWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
`

const Layout = ({footerContent, headerContent, children, lang, page }) => {
  // Enables dark mode if the user's OS has an active dark theme
  const darkModeEnabled = useDarkMode()
  const theme = darkModeEnabled ? darkTheme : lightTheme
  return (
    <StyledLayoutWrapper id="layout-wrapper" data-usecookiebar={useCookieBar}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Header content={headerContent} lang={lang} page={page} />
        <main id="main-content">{children}</main>
        <Footer content={footerContent} lang={lang} />
        {page === 'book-us' && 
          <img src="https://ws.zoominfo.com/pixel/62e2f6695d30f0008e3f7f63" width="1" height="1" style={{display: 'none'}} alt="websights"/>
        }
        {useCookieBar && <CookieBar />}
      </ThemeProvider>
    </StyledLayoutWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.any,
}

export default Layout

